<template>
  <div class="page-container">
    <div>
      <!-- <TextHeader title="最新职位" :link="toJobs" /> -->
      <div>
        <el-form>
          <FormGeneratorItem 
            v-model="values"
            :item="item"
          />
        </el-form>
      </div>
    </div>
    
  </div>
</template>
<script>

// import TextHeader from "components/textHeader";
// import JobListWrapper from "components/jobs/jobListWrapper";
import FormGeneratorItem from 'components/formGenerator/formGeneratorItem'

export default {
  name:"",
  data:()=>({
    item: {
      type: "input",
      label: "姓名",
      key: "name",
      placeholder: "请输入姓名",
      rules: [{ required: true, message: "请输入姓名" }],
    },
    values:{}
  }),
  methods:{
    // changeValue(value){
    //   console.log(value);
    // }
  },
  computed:{

  },
  watch:{
  },
  components:{
    // SearchInput,
    // HeaderContent
    // TextHeader,
    // JobListWrapper,
    FormGeneratorItem
  },
  

}
</script>
<style lang="scss">
// @import "constants/style/public";
</style>